<template>
  <b-nav-item-dropdown
    v-if="listaHabilidades.length > 0"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        class="text-body"
        icon="GridIcon"
        size="21"
      />
    </template>
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h6 class="notification-title mb-0 mr-auto">
          Setores Disponíveis
        </h6>
      </div>
    </li>

    <div class="d-flex area_setores">
      <div
        v-for="setor in listaHabilidades"

        :key="setor.subject"

        @click="setSetor(setor.subject)"
      >
     
        <div
          v-if="$can(setor.action, setor.subject)"
          class="card_setor"
        >
          <div class="setor_sigla">
            <img
              width="100%"
              :src="listaHabilidades.length > 0 ? getImg(setor.subject) : ''"
              :alt="getFirstThreeLetters(setor.subject)"
            >

          </div>

        </div>

      </div>

    </div>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown } from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BNavItemDropdown,
  },
  data() {
    return {
      listaHabilidades: [],
    }
  },
  mounted() {
    const storeUser = store && store.state.app && store.state.app.user && store.state.app.user.ability
    const localUser = JSON.parse(localStorage.getItem('userData'))
    const userAbility = storeUser || localUser.ability

    this.listaHabilidades = userAbility.filter(item => item.action === 'setor')
  },
  methods: {
    getFirstThreeLetters(str) {
      return str.substring(0, 3)
    },
    getImg(str) {
      return `${window.location.origin}/imgs/logos/${str}.png`
    },
    setSetor(setor) {
      this.$store.dispatch('app/atualizarSetorAtual', setor)
      this.$swal({
        title: 'Alteração de Setor',
        text: 'Estamos preparando o novo ambiente para você!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      })
        .then(() => {
          this.$router.push('/loading')
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.area_setores {
  gap: 16px;
  padding: 16px;
  justify-content: center;
  height: 300px;
  flex-wrap: wrap;
}

.card_setor {
  border: 1px solid #c2c2c2;
  border-radius: 15px;
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &:hover {
    box-shadow: #c2c2c2 0px 0px 15px;
    cursor: pointer;
  }
}

.setor_sigla {

  text-transform: uppercase;

  img {
    border-radius: 15px;
  }
}

.setor_descricao {
  font-size: 10px;
  text-align: center;
  color: #6c757d;
  text-transform: capitalize;
}

</style>
